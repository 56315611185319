var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col flex-grow" },
    [
      _c(
        "nav",
        {
          staticClass:
            "flex flex-row px-4 mt-3 pb-2 border-b border-blue-500 items-end"
        },
        [
          _c("v-back", {
            staticClass: "w-1/3",
            attrs: { fallback: _vm.$router.resolve({ name: "teams-overview" }) }
          }),
          _c("p", {
            staticClass:
              "w-1/3 font-semibold flex justify-center pointer-events-none",
            domProps: {
              textContent: _vm._s(_vm.$t("the_team_creation.label_add_team"))
            }
          }),
          _c("div", { staticClass: "w-1/3 flex justify-end" }, [
            _c("button", {
              staticClass: "btn btn-blue rounded-full py-1 px-2",
              class: { "btn-loading": _vm.loading },
              attrs: { disabled: _vm.loading },
              domProps: { textContent: _vm._s(_vm.$t("general.button_save")) },
              on: { click: _vm.onSubmit }
            })
          ])
        ],
        1
      ),
      _c("ul", { staticClass: "list-disc list-inside" }, [
        _vm.error
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.error) }
            })
          : _vm._e(),
        _vm.errors.has("name")
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.errors.first("name")) }
            })
          : _vm._e()
      ]),
      _vm.event !== null
        ? _c(
            "form",
            {
              staticClass: "flex flex-col flex-grow",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c("div", { staticClass: "flex-grow" }, [
                _c("div", { staticClass: "form-fieldset" }, [
                  _c("label", {
                    staticClass: "form-input-label",
                    attrs: { for: "name" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("the_team_creation.label_name")
                      )
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.team.name,
                        expression: "team.name"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "text",
                      id: "name",
                      name: "name",
                      "data-vv-validate-on": "blur",
                      placeholder: _vm.$t("general.placeholder_required")
                    },
                    domProps: { value: _vm.team.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.team, "name", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ]
          )
        : _c("v-loading")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }