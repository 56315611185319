<template>
  <div class="flex flex-col flex-grow">
    <nav class="flex flex-row px-4 mt-3 pb-2 border-b border-blue-500 items-end">
      <v-back
        class="w-1/3"
        :fallback="$router.resolve({ name: 'teams-overview'})"
      />
      <p
        v-text="$t(`the_team_creation.label_add_team`)"
        class="w-1/3 font-semibold flex justify-center pointer-events-none"
      ></p>
      <div class="w-1/3 flex justify-end">
        <button
          @click="onSubmit"
          class="btn btn-blue rounded-full py-1 px-2"
          :class="{'btn-loading': loading}"
          :disabled="loading"
          v-text="$t('general.button_save')"
        ></button>
      </div>
    </nav>
    <ul class="list-disc list-inside">
      <li
        v-if="error"
        v-text="error"
        class="form-error"></li>
      <li
        v-if="errors.has('name')"
        v-text="errors.first('name')"
        class="form-error"></li>
    </ul>
    <form
      class="flex flex-col flex-grow"
      @submit.prevent="onSubmit"
      v-if="event !== null">
      <div class="flex-grow">
        <div class="form-fieldset">
          <label
            for="name"
            v-text="$t('the_team_creation.label_name')"
            class="form-input-label"></label>
          <input
            type="text"
            id="name"
            v-model="team.name"
            name="name"
            v-validate="'required'"
            data-vv-validate-on="blur"
            class="form-input"
            :placeholder="$t('general.placeholder_required')"
          >
        </div>
      </div>
    </form>
    <v-loading v-else/>
  </div>
</template>

<script>
  import validator from '@/mixins/validator';
  import { mapActions } from 'vuex';
  import Team from '@/models/Team';
  import VLoading from '@/components/common/VLoading.vue';
  import VBack from '@/components/common/VBack.vue';

  export default {
    name: 'TheTeamCreation',
    components: {
      VLoading,
      VBack,
    },
    mixins: [validator],
    data() {
      return {
        team: new Team(),
        error: null,
        loading: false,
      };
    },
    computed: {
      event() {
        return this.$store.getters['auth/event'];
      },
    },
    watch: {
      event() {
        if (this.event !== null && !this.event.canCreateTeam) {
          this.$router.push({ name: 'not-authorized' });
        }
      },
    },
    methods: {
      ...mapActions('team', ['create']),
      onSubmit() {
        this.$validator.validate().then(() => {
          if (this.$validator.errors.items.length > 0) {
            return;
          }

          this.loading = true;
          this.create({
            event: this.event,
            team: this.team,
          })
            .then(() => {
              this.$router.push({ name: 'teams-overview' });
            })
            .catch((error) => {
              this.loading = false;

              if (error.response) {
                if (error.response.status === 404) {
                  this.$router.push({ name: 'not-found' });
                }

                if (error.response.data.errors.name) {
                  [this.error] = error.response.data.errors.name;
                } else if (error.response.data.message) {
                  this.error = error.response.data.message;
                }
              } else {
                this.error = this.$t('general.label_error');
              }
            });
        });
      },
    },
  };
</script>
